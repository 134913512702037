@import 'theme/colors';
@import 'theme/spacing';
@import 'theme/breakpoints';

.overlay {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 9rem $spc-l $spc-l;
  background-color: rgba($color-ui-text-darkest, 0.65);
}

%content {
  background-color: $colorWhite;
  padding: $spc-l;
  position: relative;
  max-width: 24rem;
  margin: auto;
}

.default {
  @extend %content;
  max-width: 24rem;
}

.white {
  @extend %content;
  max-width: 34rem;

  h3 {
    margin: 0;
  }

  main {
    margin-top: $spc-l;
    margin-bottom: $spc-xl;
    ul {
      margin-top: $spc-l;
    }
  }

  label {
    margin-bottom: $spc-l;
  }
}

.fullScreen {
  max-width: 95%;
}

@media (max-width: $smallDesktop) {
  .container {
    padding-top: $spc-l;
  }
}
