@import 'theme/colors';
@import 'theme/variables';

.badge {
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 14px;
  background-color: $color-student-accent-fuschia;
  color: $colorWhite;
  text-align: center;

  &.transparent {
    background-color: transparent;
  }
}
