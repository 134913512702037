@import 'theme/colors';
@import 'theme/snippets';
@import 'theme/spacing';
@import 'theme/breakpoints';

.modalWrapper {
  background: $colorWhite;
  border: 1px solid $color-random-gray5;
  box-shadow: 0 5px 8px 0 $color-ui-shadow-random-black7,
    0 7px 20px 0 $color-ui-shadow-random-black8;
  transition: all 0.8s;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $smallDesktop) {
    width: 100%;
  }
}

.modalHeader {
  background-color: $color-student-neutral-twilight;
  height: 3.3125rem;
  color: $colorWhite;
  font-size: 1.2rem;
  padding: $spc-s $spc-l;
  font-family: 'Source Sans Pro';
  font-weight: 300;
}

.modalHeader label {
  display: block;
  padding: 0;
}

.modalFooter {
  padding: 1.5rem;
  font-size: 14px;
}

.modalClose {
  float: right;
  cursor: pointer;
  color: $color-student-neutral-twilight;
  border-radius: 30px;
  background: $colorWhite;
  font-size: 11px;
  padding: 3px 1px;
  margin: 4px;
}

.modalClose:hover {
  background: $color-random-gray4;
}

.containerOptions {
  display: flex;
  width: auto;
  height: auto;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.containerOption {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}

.optionWrapper {
  width: 20%;
  display: flex;
  padding-top: 20px;
  @media only screen and (max-width: $smallDesktop) {
    width: 50%;
  }
}

.option {
  position: relative;
  background-color: $colorWhite;
  box-sizing: border-box;
  margin-left: 6px;
}

.option label {
  cursor: pointer;
  border: 1px solid $colorWhite;
  border-radius: 20px;
  padding: 5px 15px;

  &.checked {
    border: 1px solid $color-student-neutral-twilight;
  }
}

.option label span {
  display: block;
  color: $color-random-gray6;
  font-weight: 100;
}

.option label:hover {
  border: 1px solid $color-student-neutral-twilight;
}

.goButton {
  left: 88.26%;
  right: 10.56%;
  top: calc(50% - 14px / 2);
  font-family: 'Ubuntu';
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.255px;
  color: $colorWhite;
}

.languageSupportedmodal {
  display: inline-block;
}

.switcherButton {
  display: inline-block;
  @media only screen and (max-width: $smallDesktop - 0.1rem) {
    border-right: 2px solid $color-random-blue10;
  }
}

.switcherButton span {
  padding-left: 5px;
}

.modalOverlay {
  position: fixed;
  inset: 0px;
  background-color: $color-ui-shadow-random-white2;
  z-index: 500;
  padding: 50px 0;
  display: flex;
  justify-content: center;
}

.forNormalHeader {
  border-right: none !important;
}

.forNormalHeader button span {
  display: inline-block;
  font-family: $font-alternate;
  font-weight: 500;
  font-size: 12px;
  padding-left: 0;
  @media only screen and (max-width: $smallDesktop) {
    display: none;
  }
}

.forNormalHeader button svg {
  height: 17px;
  width: 18px;
  @media only screen and (max-width: $smallDesktop) {
    height: 24px;
    width: 24px;
  }
}

.goButton svg {
  height: 20px;
  width: 20px;
  @media only screen and (max-width: $smallDesktop) {
    height: 18px;
    width: 18px;
  }
}

.popUpSubHeader {
  font-weight: bold;
  padding-top: 20px;
}

.lineSeperator {
  width: 100%;
  color: rgba($color: $color-ui-text-darkest, $alpha: 0.1);
  border: 0.5px solid rgba($color: $color-ui-text-darkest, $alpha: 0.1);
}
