@import 'theme/variables';
@import 'theme/spacing';

.gradient {
  background-color: $color-student-neutral-light-grey;

  background: linear-gradient(
    to right,
    $color-student-neutral-dawn 0%,
    $color-student-neutral-light-grey 100%
  );

  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='$color-student-neutral-dawn',
      endColorstr='$color-student-neutral-light-grey',
      GradientType=0
    ); /* IE9 */
}

.container {
  opacity: 0.6;
}

.common {
  @extend .gradient;
  border-radius: $spc-l;
}

.title {
  @extend .common;
  height: 1rem;
  width: 65%;
  margin-bottom: $spc-xs;
}

.subTitle {
  @extend .common;
  height: $spc-m;
  width: 45%;
  margin-bottom: $spc-l;
}

.textLine1,
.textLine2,
.textLine3 {
  @extend .common;
  height: $spc-s;
  margin-bottom: $spc-xs;
}

.textLine1 {
  width: 92%;
}

.textLine2 {
  width: 98%;
}

.textLine3 {
  width: 85%;
  margin-bottom: $spc-xl;
}

.headerContainer {
  display: flex;
}
.button {
  @extend .common;
  height: 1 rem;
  width: 15px;
  margin: 0 $spc-l $spc-xs 0;
}
