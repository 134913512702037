@import 'theme/colors';
@import 'theme/variables';
@import 'theme/spacing';
@import 'theme/breakpoints';

.languagesComboBox {
  display: inline-block;
  margin: 0 1em 0 0.5em;

  @media (max-width: $sm) {
    display: block;
    margin: 0 0 0 0.5em;
  }
}
.languagesDropdown {
  min-width: 0;
  line-height: $spc-l;
  select {
    padding: 0 2rem 0 0.5rem;
    min-width: 0;
  }
  figure {
    svg {
      min-width: 15px;
      min-height: 15px;
      height: 15px;
      width: 15px;
    }
  }
}
.languagesDropdownContainer {
  display: inline-block;
  width: 300px;
  text-align: right;
  font-size: 0.8625rem;
  white-space: nowrap;
  flex: auto;

  .goButton {
    color: $color-student-midnight-teal;
    background-color: $colorWhite;
    padding: 0;
    border-radius: 0.2rem;
    font-size: 0.8625rem;
    padding: 0rem 0.3rem;
    margin-left: 0;
    margin-right: $spc-m;

    @media (max-width: $sm) {
      margin-left: auto;
      margin-right: 0;
    }

    &:hover,
    &:focus {
      background-color: $color-student-light-teal;
      color: $color-student-midnight-teal;
      text-shadow: 0 0 0.01px $color-student-midnight-teal;
    }
  }
}
