@import 'theme/spacing';

.breakWord {
  overflow-wrap: break-word;
}

.icon {
  display: flex;
  margin-top: $spc-s;
  margin-right: $spc-l;

  figure {
    margin-right: $spc-l;
  }
}
.iconWrapper {
  display: flex;
  flex-direction: row;
}

.iconLabelWithEllipsis {
  padding-top: $spc-xs;
}

.iconLabelWithEllipsis:after {
  font-size: large;
  font-weight: bolder;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.button {
  display: flex;
  margin-top: $spc-s;

  figure {
    margin-right: $spc-l;
  }
}
