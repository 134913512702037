@import 'theme/colors';
@import 'theme/variables';
@import 'grid/grid';
@import 'layout/layout';
@import 'theme/breakpoints';
@import 'theme/spacing';

.mobileNav-home {
  color: $colorBlack;
  padding: $spc-m;
  border-bottom: 1px solid $color-ui-border;
  font-family: Ubuntu;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: $color-student-accent-fuschia;
  }

  &:hover {
    background: $colorLightTeal;
  }

  a:hover,
  a:active {
    text-decoration: none;
    text-shadow: 0.5px 0 0 currentColor;
  }
}

.mobileNav {
  @extend .fc-mobile-nav;
  position: fixed;
  display: none;
  top: 0;
  left: auto;
  right: -100%;
  background-color: $colorWhite;
}

.mobileNavActive {
  @extend .fc-mobile-nav--active;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  padding-bottom: 5rem;
  overflow-y: auto;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuHeader {
  margin-bottom: 0;
}

.hamburgerBtn {
  color: $color-student-primary-teal;
  font-size: 1.2rem;

  svg {
    width: 1em;
    height: 1em;
    min-width: initial;
    min-height: initial;
  }
}

.expanded {
  h2 {
    font-weight: bold !important;
  }
  li {
    margin: 0;
    border-bottom: 1px dashed $color-ui-border;
    &:last-child {
      border-bottom: none;
    }
  }
  & > div:first-child {
    border-bottom: 1px dashed $color-ui-border;
  }
}

.accordionHeader {
  :global(.no-icon) {
    width: 0;
  }
}

.accordionSection {
  ul {
    padding: 0;
  }
  > div {
    flex-direction: row-reverse !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  border-bottom: 1px solid $color-ui-border;
}

.accordionClose {
  color: $color-student-primary-teal;
  margin: $spc-m;

  svg {
    color: inherit;
  }
}

.justLink {
  :global(.title) {
    color: $color-student-primary-teal;
    font-size: $h3-font-size;
    padding-left: 0;
  }
}

:global {
  .bigmenu-open {
    overflow-y: hidden;
  }
}

.menuTitle {
  color: $color-student-neutral-dark-grey;
  font-size: 1.6rem;
  text-align: left;
  display: inline-block;
  margin: $spc-s $spc-m $spc-xs $spc-m;
}

.menuFooter {
  color: $color-student-neutral-dark-grey;
  padding: $spc-xs $spc-m;

  p {
    margin-bottom: 0;
  }
}

.bottomMenu {
  border-top: 1px dashed $color-ui-border;
  padding-top: $spc-xs;
}

.logoutBtn {
  color: $colorWhite;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  padding: $spc-s $spc-m;
  background: linear-gradient(180deg, $color-student-primary-teal 0%, #005a61 100%);
  box-shadow: 0 1px 1px 0 $color-ui-shadow-random-black4;
  border: 1px solid $color-random-cyan4;
  text-transform: uppercase;
  font-family: Ubuntu;
}

.avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: $colorDangerPurple;
  border-radius: 50%;
  font-size: 16px;
  margin-right: $spc-s;
  color: $colorWhite;
  font-weight: normal !important;
}
