@import 'theme/colors';
@import 'theme/breakpoints';
@import 'theme/spacing';
@import 'colors/brand-colors';
@import 'theme/variables';

.pageContent {
  // this is screen only. Otherwise printing in firefox
  // outputs only part of the page
  @media screen {
    display: flex;
    flex-direction: column;
  }

  background-color: $color-student-neutral-dawn;

  :global(.col) > :global(.card) {
    margin-bottom: $spc-m;

    // don't show Col if without content
    &:empty {
      display: none;
    }
  }
}

.hideQuickLinks {
  display: none;
}

.heading {
  vertical-align: middle;
  padding: $spc-s $spc-xl;
  margin: 0;
  color: $colorWhite;
  display: flex;
  align-items: stretch;
  background-color: $color-student-neutral-dark-grey;
  margin-bottom: 10px;
}

.contentWrapper {
  padding: 0 $spc-l $spc-l;
  margin-top: $spc-l;

  @media (max-width: $largePhone) {
    padding: 0 $spc-s $spc-s;
    margin-top: $spc-s;
  }
}

@media (max-width: $smallDesktop) {
  .hasQuickLinks {
    display: flex;
    flex-direction: column-reverse;
    > div {
      flex-basis: auto;
    }
  }
}
