@import 'theme/colors';
@import 'theme/variables';
@import 'theme/spacing';

.menuItem {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: $spc-xs $spc-m;
  white-space: break-spaces;
  font-family: Ubuntu;
  cursor: pointer;

  &:hover {
    background: $colorLightTeal;
    text-decoration: none;
    &.link {
      text-shadow: 0.5px 0 0 currentColor;
    }
  }
}

@media (max-width: 48.1rem) {
  .dottedWrapper {
    padding: $spc-m;
    border-bottom: 1px dashed $color-ui-border;
  }
}

.icon {
  margin: 0;
  svg {
    color: $color-student-accent-fuschia;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: $spc-s;
  }
}

.link {
  color: $color-student-medium-teal;
}
