@import 'theme/colors';
@import 'theme/variables';
@import 'theme/spacing';

.centerAlignedText {
  color: $colorBlack;
  text-align: center;
}

.headed2LogoWidth {
  width: 40%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.largeText {
  font-size: 16px;
  color: $color-gray-800;
  font-weight: 400;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: -1px;
  white-space: pre-wrap;
  line-height: 24px;
}

.largeLinkedStatusText {
  font-size: 16px;
  color: $color-shark;
  font-weight: 400;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: -1px;
  white-space: pre-wrap;
  line-height: 24px;
}

.largeBoldText {
  font-size: 16px;
  color: $color-gray-800;
  font-weight: 700;
  white-space: pre-wrap;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: -15px;
}

.smallText {
  font-size: 12px;
  color: $color-random-cyan5;
  white-space: pre-wrap;
  font-weight: 400;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}

.buttons {
  // swapping position of primary and secondary button via CSS
  display: flex;
  flex-direction: row-reverse;

  // add spacing to the primary button
  & button:nth-child(1) {
    margin-left: 16px;
  }
}

// TODO: this is a temporary solution in order to meet a deadline while the Neon team prepares an official solution
// since this is temporary, using !important
.hideModalHeader header {
  display: none !important;
}
