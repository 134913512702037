@import 'theme/variables';
@import 'grid/grid';

// resolves bugs in Firefox printing engine
// that seems to occur on some flex box combinations
.fc-grid__row {
  @media print {
    display: block;
  }
}
