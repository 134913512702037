@import 'theme/colors';
@import 'theme/variables';
@import 'components/tabs/tabs';
@import 'theme/spacing';
@import 'theme/variables';

%clickHOCButton {
  font-size: 1.2rem;
  color: $color-student-primary-teal-button;
  cursor: pointer;
  background-color: $colorWhite;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  box-sizing: border-box;
  line-height: 2rem;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  transition: 0.075s linear background-color;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    font-weight: normal !important;
  }

  &.block {
    width: 100%;
  }

  &.xs {
    font-size: 0.9rem;
    padding: 0rem 1rem;

    &:hover,
    &:focus {
      padding: calc(0rem - 3px) calc(1rem - 3px);
    }
  }

  &.small {
    padding: 0.3rem 1rem;

    &:hover,
    &:focus {
      padding: calc(0.3rem - 3px) calc(1rem - 3px);
    }
  }

  &.medium {
    padding: 0.5rem 2rem;

    &:hover,
    &:focus {
      padding: calc(0.5rem - 3px) calc(2rem - 3px);
    }
  }

  &.large {
    font-size: 1.44rem;
    line-height: 2rem;
    padding: 1rem 3rem;

    &:hover,
    &:focus {
      padding: calc(1rem - 3px) calc(3rem - 3px);
    }
  }
}

.default {
  @extend %clickHOCButton;
  color: $color-student-primary-teal-button;
  background-color: $colorWhite;
  border-color: $color-student-primary-teal-button;

  &:hover,
  &:focus {
    border: 4px solid $color-student-primary-teal-button;
    background-color: $colorWhite;
    color: $color-student-primary-teal-button;
    text-shadow: 0 0 0.01px $color-student-primary-teal-button;
    text-decoration: none;
  }

  &:disabled {
    color: $color-ui-text-muted;
    border-color: $color-ui-shadow-random-cyan1;

    &:hover {
      border-color: $color-ui-shadow-random-cyan1;
      background-color: $color-ui-shadow-random-cyan1;
      color: $colorWhite;
      font-weight: normal !important;
    }
  }

  &.ghost {
    border: 1px solid $colorWhite;
    color: $colorWhite;
    background-color: $color-ui-shadow-random-white1;

    &:hover {
      background-color: $colorWhite;
      color: $color-student-primary-teal-button;
    }

    &:disabled {
      // TODO

      &:hover {
        // TODO
      }
    }
  }
}

.light {
  @extend %clickHOCButton;
  color: $color-student-midnight-teal;
  background-color: $colorWhite;
  border-color: $colorWhite;

  &:hover,
  &:focus {
    border: 4px solid $color-student-light-teal;
    background-color: $color-student-light-teal;
    color: $color-student-midnight-teal;
    text-shadow: 0 0 0.01px $color-student-midnight-teal;
    text-decoration: none !important;
  }

  &:disabled {
    color: $color-ui-text-muted;

    &:hover,
    &:focus {
      background-color: $colorWhite;
      border-color: $colorWhite;
    }
  }

  &.ghost {
    border: 1px solid $colorWhite;
    color: $colorWhite;
    background-color: $color-ui-shadow-random-white1;

    &:hover {
      background-color: $colorWhite;
      color: $color-student-accent-fuschia;
    }
  }
}

.primary {
  @extend %clickHOCButton;
  background-color: $color-student-primary-teal-button;
  color: $colorWhite;
  border-color: $color-student-primary-teal-button;

  &:hover,
  &:focus {
    border: 4px solid $color-student-primary-teal-button;
    background-color: $colorWhite;
    color: $color-student-primary-teal-button;
    text-shadow: 0 0 0.01px $color-student-primary-teal-button;
  }

  &:disabled {
    color: $colorWhite;
    background-color: $color-ui-shadow-random-cyan1;
    border-color: $color-ui-shadow-random-white1;

    &:hover {
      background-color: $color-ui-shadow-random-cyan2;
      border-color: $color-ui-shadow-random-white1;
    }
  }

  &.ghost {
    border: 1px solid $color-student-primary-teal-button;
    color: $color-student-primary-teal-button;
    background-color: $color-ui-shadow-random-white1;

    &:hover {
      background-color: $color-student-primary-teal-button;
      color: $colorWhite;
    }

    &:disabled {
      color: $color-ui-text-muted;
      border-color: $color-ui-shadow-random-cyan1;

      &:hover {
        border-color: $color-ui-shadow-random-cyan1;
        background-color: $color-ui-shadow-random-cyan1;
        color: $colorWhite;
      }
    }
  }
}

.callToAction {
  @extend %clickHOCButton;
  background-color: $color-student-accent-fuschia;
  color: $colorWhite;
  border-color: $color-student-accent-fuschia;

  &:hover,
  &:focus {
    border: 4px solid $color-student-accent-fuschia;
    background-color: $colorWhite;
    color: $color-student-accent-fuschia;
    text-shadow: 0 0 0.01px $color-student-accent-fuschia;
    text-decoration: none;
  }

  &:disabled {
    color: $colorWhite;
    background-color: $color-ui-shadow-random-cyan1;
    border-color: $color-ui-shadow-random-white1;

    &:hover {
      background-color: $color-ui-shadow-random-cyan2;
      border-color: $color-ui-shadow-random-white1;
    }
  }

  &.ghost {
    border: 1px solid $color-student-accent-fuschia;
    color: $color-student-accent-fuschia;
    background-color: $color-ui-shadow-random-white1;

    &:hover {
      background-color: $color-student-accent-fuschia;
      color: $colorWhite;
    }

    &:disabled {
      color: $color-ui-text-muted;
      border-color: $color-ui-shadow-random-cyan1;

      &:hover {
        border-color: $color-ui-shadow-random-cyan1;
        background-color: $color-ui-shadow-random-cyan1;
        color: $colorWhite;
      }
    }
  }
}

.danger {
  @extend %clickHOCButton;
  background-color: $color-ui-background-danger;
  color: $colorWhite;
  border-color: $color-ui-background-danger;

  &:hover,
  &:focus {
    border: 4px solid $color-ui-background-danger;
    background-color: $colorWhite;
    color: $color-ui-background-danger;
    text-shadow: 0 0 0.01px $color-ui-background-danger;
  }

  &:disabled {
    color: $colorWhite;
    background-color: $color-ui-shadow-random-red1;
    border-color: $color-ui-shadow-random-white1;
  }
}

.alternate {
  @extend %clickHOCButton;
  background-color: $color-student-accent-grape;
  color: $colorWhite;
  border-color: $color-student-accent-grape;

  &:hover,
  &:focus {
    border: 4px solid $color-student-accent-grape;
    background-color: $colorWhite;
    color: $color-student-accent-grape;
    text-shadow: 0 0 0.01px $color-student-accent-grape;
  }

  &:disabled {
    color: $colorWhite;
    background-color: $color-ui-shadow-random-blue1;
    border-color: $color-ui-shadow-random-white1;
  }
}

%resetButtonChrome {
  background: none;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: $color-ui-shadow-random-black5;
  font-family: inherit;
  font-weight: normal;
  font-size: inherit;
}

.noChrome {
  @extend %resetButtonChrome;
  border: none;
}

.pointer {
  cursor: pointer;
}

%navLinkBase {
  @extend %resetButtonChrome;
  white-space: nowrap;
  padding: 0 $spc-l;
  width: auto;
  text-decoration: none;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: $color-student-neutral-dark-grey;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.66667;
  border-top: none;
  border-left: none;
  border-right: none;

  &button {
    padding: $spc-s $spc-l;
  }
}

.navLink {
  @extend %navLinkBase;
  border-bottom-color: transparent;
  border-bottom-width: 0.27778rem;
}

.navLinkSelected {
  @extend %navLinkBase;
  @extend .fc-tabs__label--active;
  background: none;
  font-weight: initial;
}

.link {
  @extend %resetButtonChrome;
  border: none;
  font-size: 1rem;
  color: $color-student-primary-teal-button;
  cursor: pointer;

  &:hover {
    &:not([disabled]) {
      color: $color-student-primary-teal-button;
      text-decoration: underline;
    }
  }
}

.addButton {
  color: $colorWhite;
  background-color: $color-student-primary-teal-button;
  width: 100%;
  display: block;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 1rem;
  cursor: pointer;
  border: none;
  transition: 0.075s linear background-color;

  &:hover {
    background-color: $color-student-medium-teal;
  }

  &:disabled,
  &:disabled:hover {
    background-color: rgba($color-student-primary-teal-button, 0.5);
    cursor: not-allowed;
    user-select: none;
  }
}
