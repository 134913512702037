@import 'theme/colors';
@import 'theme/spacing';

.bar {
  background-color: $color-student-neutral-day;
  color: $color-student-neutral-twilight;
  padding: 0 $spc-l;
  min-height: 2.75rem;
  display: flex;
  align-items: center;
}

.stretch {
  align-items: stretch;
}

.bottom {
  padding: $spc-s $spc-l;

  button {
    margin-right: $spc-s;
  }
}

.top {
  background-color: inherit;
}

.small {
  justify-content: flex-end;

  button + button {
    margin-left: $spc-m;
  }
}
