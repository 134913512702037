@import 'theme/variables';
@import 'icons/icons';

.icon {
  display: inline-block;
  margin: 0 .5em;

  svg {
    @extend .fc-icon;
  }
}
