@import 'theme/colors';
@import 'theme/snippets';
@import 'theme/spacing';
@import 'theme/variables';

.card {
  flex: auto;
}

.hoverable:hover {
  @extend %cardShadow;
}

.shadow {
  @extend %cardShadow;
}

.margin {
  margin-top: $spc-l;
}
.padding {
  padding: $spc-m;
  background: $color-white;
  margin-top: 24px;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.06), 0px 0px 6px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}
.cardCollegePage {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  border-width: 1px 0px;
}
.cardMoveApplicationPage {
  box-shadow: none;
  background: $global-background-gray;
  border-radius: 0px;
  padding: 24px;
}
