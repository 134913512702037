@import 'theme/colors';
@import 'theme/spacing';
@import 'theme/variables';

.icon {
  color: $color-student-primary-teal;
  position: absolute;
  right: 0;
  pointer-events: none;
  svg {
    width: 25px;
    height: 25px;
  }
}

.selectWrapper {
  display: block;
  position: relative;
  border-bottom: 1px solid lightgray;
  padding: 2px;
  margin-bottom: 0;
  font-weight: normal;
  overflow: hidden;
  min-width: 210px;
}

/* fix for Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0) {
  .select {
    background-image: none;
    padding: 5px;
  }
}

.select {
  // reset chrome
  -webkit-appearance: none;
  -webkit-tap-highlight-color: $color-ui-shadow-random-black5;
  -moz-appearance: none;
  border-radius: 0;
  font: inherit;
  background: none;
  border: none;
  box-shadow: none !important;
  &:-webkit-autofill {
    color: $colorWhite !important;
  }
  &::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
  }
  &:invalid {
    border: 1px solid $color-ui-background-danger;
  }

  padding-right: 2rem;
  width: 100%;
  min-width: 200px;
}

.label {
  padding-right: $spc-m;
  display: inline-block;
  font-weight: bold;
  display: block;
  margin-right: 2rem;
}

.inline {
  .maybeLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .selectWrapper {
    flex: 1;
  }
}

.invertColor {
  color: $colorWhite;

  .icon,
  .select {
    color: inherit;
  }

  option {
    color: initial;
  }
}
