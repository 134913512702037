@import 'theme/variables';

.header {
  border-bottom: 1px solid $color-ui-border;
  margin-bottom: 1rem;

  h1 {
    text-align: center;
    padding-bottom: 0.5rem;
    margin: 0;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
}

.logOut {
  flex-basis: 40%;
  margin-right: 1rem;
}

.extendSession {
  flex-basis: 60%;
  margin-left: 1rem;
}

.modalWrapper {
  // Increasing z-index more than the neon desing modal.
  z-index: 9999;
}
