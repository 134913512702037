.studentHelpCenterLink {
  margin-top: 8px;

  padding-right: 0.31rem;
  padding-left: 10px;

  svg {
    height: 20px;
    width: 20px;
  }
}
