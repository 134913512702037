@import 'theme/colors';
@import 'theme/spacing';
@import 'theme/variables';
@import 'theme/breakpoints';

.dark,
.green,
.warning,
.alert,
.smalldark,
.midnight,
.grey,
.lightgrey,
.goal,
.general,
.lightpurple,
.fuschia,
.white {
  text-align: left;
  font-family: $font-alternate;
  color: $colorWhite;
  min-height: 4.75rem;
  padding: $spc-m;
  display: flex;
  align-items: flex-end;
  border-width: 1px 1px;
  border-style: solid;
  border-color: $global-border;
  border-bottom: 1px solid $global-border !important;
  background: $color-white;

  svg {
    color: inherit;
    min-height: 30px;
    min-width: 34px;
  }

  h3 {
    flex: 1;
  }

  @media (max-width: $largePhone) {
    flex-direction: column;
    align-items: stretch;
  }
}
.headerComponent {
  flex: 1;
  margin-bottom: 0;
}

.dark {
  background-color: $color-student-neutral-twilight;
  position: relative;

  &:after {
    content: '';
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    // prettier-ignore
    background-image: linear-gradient(
      to right,
      $color-student-accent-grape,
      $color-student-accent-grape 40%,
      $color-student-accent-fuschia 40%,
      $color-student-accent-fuschia 70%,
      $color-student-primary-teal 70%,
      $color-student-primary-teal 85%,
      $color-student-accent-lime 85%,
      $color-student-accent-lime
    );
  }
}

.smalldark {
  background-color: $color-student-neutral-twilight;
  height: 3.3125rem;
  padding: $spc-xs $spc-m;
  display: flex;
  align-items: center;

  h3 {
    font-size: 1.2rem !important;
  }

  svg {
    min-height: 26px;
    min-width: 26px;
  }
}

.green {
  background-color: $color-student-primary-teal;
}

.warning {
  background-color: $color-student-alert-orange;
}

.alert {
  background-color: $color-ui-background-danger;
}

.lightpurple {
  background-color: $color-gray-100;
  border-bottom: 1px solid $color-gray-200;
  min-height: 0px !important;
  // color: $colorBlack;
  // position: relative;

  // &:after {
  //   content: '';
  //   height: 3px;
  //   width: 100%;
  //   position: absolute;
  //   bottom: 0px;
  //   left: 0;
  //   // prettier-ignore
  //   background-image: linear-gradient(
  //     to right,
  //     $color-student-accent-grape,
  //     $color-student-accent-grape 40%,
  //     $color-student-accent-fuschia 40%,
  //     $color-student-accent-fuschia 70%,
  //     $color-student-primary-teal 70%,
  //     $color-student-primary-teal 85%,
  //     $color-student-accent-lime 85%,
  //     $color-student-accent-lime
  //   );
  // }
}

.midnight {
  background-color: $color-student-neutral-midnight;
  min-height: 3.2rem;
  padding: 0 0 0 $spc-m;
  align-items: center;

  @media (max-width: $largePhone) {
    flex-direction: column;
    align-items: stretch;
  }
}

.purple {
  height: 72px;
  border-radius: 0 0 5px 5px;
  background-color: $colorDangerPurple;
  box-shadow: 0 1px 1px 0 $color-ui-shadow-random-black2;
  padding-top: 0.5%;
  padding-left: 1%;
  color: $colorWhite;
  background: linear-gradient(195.81deg, #6e61e2 0%, #544bcb 100%);
  @media (max-width: $largePhone) {
    width: 367px;
    height: 71px;
    left: 4px;
    top: 105px;
    background: $color-random-blue7;
    border-radius: 0px;
  }
}

.srr_header_text {
  height: 40px;
  width: 463px;
  color: $colorWhite;
  font-family: '.SF NS Display';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40px;
  text-shadow: 0 2px 4px 0 $color-ui-shadow-random-black3;

  @media (max-width: $largePhone) {
    position: absolute;
    width: 324px;
    height: 39px;
    left: 19px;
    top: 105px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    /* identical to box height, or 160% */
    letter-spacing: 0.576px;
    color: $colorWhite;
  }

  @media (max-width: $smallPhone) {
    font-size: 21px;
  }
}

.grey {
  background-color: $color-student-neutral-grey;
}

.lightgrey,
.goal,
.general {
  background-color: $color-student-neutral-day;
  color: $color-student-neutral-twilight;
}

.goal {
  display: flex;
  align-items: baseline;
  color: $color-student-primary-teal;

  :global(.h3):before {
    content: 'Related to GOAL';
    padding-right: $spc-m;
    color: $color-student-neutral-twilight;
  }
}

.general {
  :global(.h3):before {
    content: ' ';
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin: 0 $spc-s 0 0;
    vertical-align: sub;
    background: $color-student-neutral-twilight;
  }
}

.more {
  color: $colorWhite;

  figure {
    margin: 0 0 0 $spc-xs;
    transform: rotate(90deg);
  }

  svg {
    min-width: 16px;
    min-height: 18px;
  }
}

.white {
  position: relative;
  padding: $spc-s;
  min-height: 3.2rem;
  border-bottom: 1px solid $color-student-neutral-dawn;

  h3 {
    width: 100%;
    color: $color-student-neutral-dark-grey;
    text-align: center;
    font-size: 1.2rem !important;
  }

  svg {
    min-width: 16px;
    min-height: 18px;
  }
}

.fuschia {
  background: $colorAccentFuschia;
  h3 {
    width: 100%;
    font-size: 1.2rem !important;
  }
}

.collapsable {
  color: $colorWhite;

  figure {
    margin: 0 0 0 $spc-xs;
  }

  svg {
    min-width: 16px;
    min-height: 18px;
  }
}

.commonHeaderCollegePage{
  border-width: 1px 0px;
}
