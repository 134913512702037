@import 'theme/colors';

.label {
  font-weight: bold;
  display: block;
  padding: 0.5rem 0;
}

.inline {
  display: inline-block;
  margin-right: 2rem;
}

.light {
  font-weight: normal;
}

.required {
  &:before {
    content: '* ';
    color: $color-student-alert-red;
  }
}

.validation {
  font-weight: normal;
}

.icon {
  color: $color-student-alert-red;
}
