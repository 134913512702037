@import 'theme/colors';
@import 'theme/snippets';
@import 'theme/spacing';
@import 'theme/breakpoints';

.iconButton {
  @extend %nowrap;
  color: $color-student-primary-teal;
  font-size: 1rem;
  padding-right: $spc-xs;

  svg {
    color: $color-student-primary-teal;
    width: 1rem;
    height: 1rem;
  }
}

.buttonLabel {
  @media (max-width: $md) {
    display: none;
  }
}
