@import 'theme/colors';
$xs: 425px;

.container {
  position: fixed;
  top: 180px;
  z-index: 99;
  right: 0;
  background-color: $color-random-blue4;
  display: none;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem 0;
  @media only screen and (min-width: $xs) {
    min-width: 433px;
    top: 120px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.infoIcon {
  svg {
    color: $color-random-blue6;
    width: 26.67px;
    height: 26.67px;
  }
}

.closeIcon,
.infoIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid $color-random-blue6;
  border-color: $color-random-blue6;
  border-radius: 50%;
  color: $color-random-blue6;
  cursor: pointer;
  svg {
    width: 8px;
    height: 8px;
    margin: 0;
  }
}

.infoIcon {
  cursor: default;
  width: 26.67px;
  height: 20.67px;
  svg {
    color: $color-random-blue6;
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: $xs) {
    width: 26.67px;
    height: 26.67px;
  }
}

.notificationText {
  color: '#333333';
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.rightToLeftAnimation {
  display: flex;
  animation: rightToLeftAnimationFn 1s;
  animation-fill-mode: forwards;
}

.LeftToRightAnimation {
  animation: LeftToRightAnimationFn 1s;
  animation-fill-mode: forwards;
}

@keyframes rightToLeftAnimationFn {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0px);
    @media only screen and (min-width: $xs) {
      transform: translateX(-10px);
    }
  }
}

@keyframes LeftToRightAnimationFn {
  100% {
    transform: translateX(600px);
  }
  0% {
    transform: translateX(0px);
    @media only screen and (min-width: $xs) {
      transform: translateX(-10px);
    }
  }
}
