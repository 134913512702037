@import 'theme/colors';
@import 'theme/snippets';
@import 'theme/spacing';
@import 'theme/variables';

.card {
  background: $colorWhite;
  flex: auto;
}

.hoverable:hover {
  @extend %cardShadow;
}

.shadow {
  @extend %cardShadow;
}

.margin {
  margin-top: $spc-l;
}
.padding {
  padding: $spc-m;
}
