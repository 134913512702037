@import 'theme/spacing';

.button {
  font-size: 1rem;
  text-transform: uppercase;
}

.closeButton {
  font-size: 1.5rem;
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.topright {
  position: absolute;
  right: 0;
  top: 0;
}
