@import 'theme/colors';
@import 'theme/breakpoints';

.row {
  background-color: $color-student-neutral-dark-grey;
  color: $colorWhite;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.95em 0 0.95em;
  font-size: 1em;
  letter-spacing: 0.023em;
  line-height: 1.7em;
}
.asideColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileIcon {
  font-size: 3.4em;
  margin: 0;
  padding: 0;
}
.link {
  color: $colorWhite;
}

@media (max-width: $smallDesktop) {
  .content {
    padding: 0 2em 0 2em;
  }
}
