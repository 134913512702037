@import 'theme/colors';
@import 'theme/spacing';
@import 'theme/variables';

.item {
  padding: $spc-m 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  text-align: left;
  outline-offset: -3px;
  &:hover {
    text-decoration: none;

    .innerContainer {
      color: darken($color-student-accent-grape, 15%);
    }
  }
}

.innerContainer {
  flex: 1;
  color: $color-student-accent-grape;
  max-width: 25rem;
  > h2 {
    font-size: 18px !important;
  }
}

.title {
  color: $color-gray-800;
  margin: 0 !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 18px;
    div{
      font-weight: 600 !important;
    }
}

.text {
  white-space: normal;
}

.icon {
  color: $colorRed;
  margin-left: 0;
  margin-right: $spc-s;
  margin-bottom: 9px;
  svg {
    margin-top: 5px;
    fill: $button-tertiary;
  }
}

.noIcon {
  padding-left: 60px;
}

.iconRight {
  color: $color-student-neutral-light-grey;
  svg {
    min-width: 20px;
    min-height: 20px;
  }
}

.withBorder {
  border-bottom: 1px solid $color-student-neutral-light-grey;
}

.highlighted {
  background: $color-student-neutral-day;
}

.caption {
  color: $text-color-light;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 58px;
  margin-top: -5px;
}
