@import 'theme/colors';
@import 'theme/snippets';
@import 'theme/spacing';
@import 'theme/variables';

.errorContainer {
  padding: $spc-xl;
  height: 70vh;
}

.errorSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 16em;
  }
  p {
    margin: $spc-s;
  }
}

.errorSectionImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorTitle {
  color: $color-student-neutral-grey;
  font-size: 4em;
}

.errorMessage {
  font-size: 2em;
  color: $color-student-neutral-midnight;
  font-weight: bold;
}

.errorDescription {
  font-size: 1.5em;
  color: $color-student-neutral-midnight;
}

.errorAction {
  font-size: 1.2em;
  color: $color-student-neutral-dark-grey;
}

.errorImage {
  width: 20em;
}
