@import 'theme/spacing';
@import 'theme/variables';
@import 'theme/breakpoints';

.section {
  border-bottom: 1px solid $color-ui-border;
  width: 100%;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.linkedHeader {
  margin-bottom: 0;
  padding: $spc-xs 0 $spc-xs 3.2em;

  & > a {
    width: 100%;

    & > figure {
      width: 1.5rem;
      margin-left: calc(-1.5rem - 0.75em);
    }
  }

  & > figure {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.sectionChildren {
  transition: height 0.25s;
  overflow: hidden;
  margin-bottom: 12px !important;
}

.sectionChildrenInner {
  display: flex;
  flex-direction: column;
  padding: 0 0 $spc-l 0;
  list-style: none !important;
  margin: 0;
  margin-top: -6px !important;
  margin-bottom: 10px !important;
  & > a,
  & > li > a {
    display: block;
    padding-left: $spc-xxl;
  }
}

.caret {
  color: $color-ui-primary;
  transition: transform 0.25s;
  font-size: 1.5em;
  text-overflow: clip;
  flex: none;
  order: 1;
  margin-top: 12px;
}

.caretButton {
  svg {
    // transform: scale(1.5);
  }
  &--big {
    svg {
      // transform: scale(1.2);
    }
  }
  &:hover,
  &:focus {
    svg {
      background: $color-ui-primary;
      color: $color-ui-text-light;
      border-radius: 50%;
    }
  }
}

.expandedCaret {
  margin-top: 0px;
  transform: rotate(-180deg);

}

.icon {
  font-size: 1.5em;
}

.pathway {
  margin-top: $spc-l;
}

.customHeaderContainer {
  flex: none;
  order: 0;
}
