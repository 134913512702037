@import 'theme/colors';
@import 'theme/variables';
@import 'theme/spacing';
@import 'theme/breakpoints';
@import 'components/alerts/alerts';

.component {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @extend .fc-alert;

  a {
    text-decoration: underline;
  }

  &.danger {
    @extend .fc-alert--danger;

    a {
      color: $colorWhite;
    }

    .actions {
      button {
        color: $colorWhite;
      }
    }
  }

  &.success {
    @extend .fc-alert--success;
    // override for FCUI - should update the css framework
    background-color: $color-ui-background-success;
  }

  &.successLight {
    // override for FCUI - should update the css framework
    background-color: $color-random-green1;
  }

  &.info {
    @extend .fc-alert--info;
  }

  &.warning {
    @extend .fc-alert--warning;
  }

  &.custom {
    background-color: $colorCerulean;
  }
  padding: $spc-m;
  // padding-left: 0;
  // padding-right: $spc-l;
}

.alert {
  margin-bottom: $spc-l;
}

.icon {
  margin-right: $spc-l;
  font-size: 1.2em;
}
.iconLight {
  svg {
    height: 22px;
    width: 22px;

    circle {
      fill: $color-random-green2;
    }
  }
}

.closeButton {
  figure {
    margin: 0 $spc-xs 0 0;
  }
}

.showFixesButton {
  figure {
    margin: 0 0 0 $spc-xs;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  align-self: stretch;
  min-width: 25rem;
  margin-top: -0.93rem;
  margin-bottom: -0.93rem;

  button {
    flex-wrap: nowrap;
    line-height: 2rem;
    border-right: 1px solid $colorWhite;
    padding: 0 1rem;

    figure {
      font-size: 1.2em;
    }
  }

  button:last-child {
    border-right: 0;
    padding-right: 0;
  }
}

.title {
  text-transform: capitalize;
}

.content {
  display: flex;
  max-width: 80%;
  align-items: flex-start;
  overflow: hidden;
}

.fixes {
  background-color: $colorWhite;
  color: $colorBlack;
  padding: $spc-l $spc-xl;
  border: 1px solid $colorSteel;
  border-top: 0;
  margin-top: -$spc-l;
  margin-bottom: $spc-l;

  .fixes_list {
    margin: 0;
  }
}

@media (max-width: $tablet) {
  .actions {
    min-width: 8rem;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: flex-start;
    margin: 0;

    button {
      border: 0;
      padding: 0;
    }
  }

  .closeButton {
    span {
      display: none;
    }

    figure {
      margin: 0;
    }
  }
}
