.__mfe__left-nav-container {
  background-color: #ffffff;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.04);
  min-height: 100vh;
  height: 100%;
}

.__mfe__left-nav-container h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  padding: 2px 16px;
  margin: 0;
}

.__mfe__left-nav-content-launch-wrapper {
  align-self: flex-end;
  text-align: right;
}

.__mfe__input-field {
  width: 100%;
}

.__mfe__main-container {
  background: var(--__neon__color-gray-100);
}

.floatingIcon {
  position: fixed;
  bottom: 100px;
  right: 45px;
  background-color: #00ffff;
  box-shadow: 8px 0 8px rgba(0, 0, 0, 0.08), 8px 0 8px rgba(0, 0, 0, 0.04);
  font-size: 20px; // increase font size
  font-weight: bold; // make text bold
}
