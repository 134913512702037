/*
  USE NEON COLORS WHEN POSSIBLE found in this file:
  node_modules/@ps-refarch-ux/neon/src/cdn-assets/styles/shared/_design-tokens.scss
*/
@import 'styles/shared/design-tokens';

/*
  Old NavStudent Colors: Try to NOT create new colors.
  Use either Neon Colors from the imported design-tokens file above
  or use existing colors.  Colors with Opacity are located at the bottom,
  so they can use existing color definitions.
*/
$colorBlack: #000000;
$colorPrimaryTeal: #00727a;
$color-student-primary-teal-link: $colorPrimaryTeal; // components/ClickHOC/styles.scss
$color-student-primary-teal-button: $colorPrimaryTeal; // components/ClickHOC/styles.scss
$colorCerulean: #009cd5;
$colorDeepTeal: #015658;
$colorMediumTeal: #016469;
$colorEggPlant: #07081c;
$colorGreen: #189c2d;
$colorMidGreen: #1a844f;
$color-shark: #202124;
$colorBrightGreen: #41d98d;
$colorDangerPurple: #544cd9;
$colorAccentGrape: #5e56f0;
$colorInfoPurple: #5e56f0;
$colorSteel: #7b7b97;
$colorNeutralLightGrey: #a0a1ba;
$colorGray: #b1b1b1;
$colorDuckEggBlue: #c7f9fa;
$colorLightPurple: #c9c6ff;
$colorAccentFuschia: #d20061;
$colorLightGray: #dadada;
$colorNeutralDawn: #e6e6f1;
$colorTomato: #ea3939;
$colorLightCyan: #eafeff;
$colorRed: #eb2a2a;
$colorOrange: #ef670d;
$colorVeryLightGray: #f0f0f0;
$colorLightTeal: #f0feff;
$colorNeutralDay: #f6f6fa;
$colorLightYellow: #fffde5;
$colorWhite: #ffffff;

/*
  Hex codes of random colors recently moved from other scss files
*/
$color-randome-white1: #fbfbfe;
$color-random-cyan1: #005a60;
$color-login-button-teal-gradient-dark: $color-random-cyan1; // components/NewLogin/style.scss
$color-random-cyan2: #007279;
$color-random-cyan3: #00838c;
$color-login-button-teal-gradient-light: $color-random-cyan3; // components/NewLogin/style.scss
$color-college-footer-clickable: $color-random-cyan3; // components/CollegeFooter/style.scss
$color-random-cyan4: #00595f;
$color-random-cyan5: #677274;
$color-random-cyan6: #dfe2e2;
$color-random-cyan7: #03747c;
$color-random-gray1: #f3f3f3;
$color-random-gray2: #e4e4eb;
$color-random-gray3: #979797;
$color-random-gray4: #cccccc;
$color-random-gray5: #d0cccc;
$color-random-gray6: #717171;
$color-random-gray7: #f6f7f8;
$color-random-gray8: #eeeeee;
$color-random-gray9: #3c3d3f;
$color-random-gray10: #f0f0f6;
$color-random-gray11: #c2c2c2;
$color-random-gray12: #dddddd;
$color-random-blue1: #ebebf8;
$color-random-blue2: #d9d9f2;
$color-random-blue3: #e2e0ff;
$color-random-blue4: #e5f3ff;
$color-random-blue5: #efeffb;
$color-random-blue6: #0075db;
$color-random-blue7: #665bdb;
$color-random-blue8: #4b44c5;
$color-random-blue9: #19182d;
$color-random-blue10: #4e47c5;
$color-random-blue11: #0d0c17;
$color-random-blue12: #312d77;
$color-random-blue13: #dbdbeb;
$color-random-blue14: #1f1f9b;
$color-random-blue15: #e0dbfc;
$color-random-blue16: #5e56f1;
$color-random-red1: #ff9888;
$color-random-red2: #ff6047;
$color-random-red3: #ffd1ca;
$color-random-red4: #feedef;
$color-random-red5: #c70029;
$color-random-red6: #e31b30;
$color-random-red7: #fbddda;
$color-random-red8: #930c0c;
$color-random-red9: #ba100c;
$color-random-red10: #bf0023;
$color-random-red11: #fdedef;
$color-random-red12: #ff0000;
$color-random-red13: #ff3232;
$color-random-black1: #2d2d2d;
$color-random-black2: #272728;
$color-random-black3: #171717;
$color-random-green1: #d9fcf2;
$color-random-green2: #0fab7b;
$color-random-green3: #8bc200;

/*
  These are redeclarations to override and switch to Neon.
  Remove any duplicates from above and uncomment this section
  to swtich to Neon colors. There should be a mapping below for
  every color above that can be mapped.
*/

// Mapping of existing colors to closest Neon color -or- FC-UI Neon spec

// /* BEGIN - UNCOMMENT THIS SECTION TO ENABLE THE NEON OVERRIDES

$colorBlack: #000000;
$colorPrimaryTeal: #00727a;
$colorCerulean: #009cd5;
$colorDeepTeal: #015658;
$colorMediumTeal: #016469;
$colorEggPlant: #07081c;
$colorGreen: #189c2d;
$colorMidGreen: #1a844f;
$color-shark: #202124;
$colorBrightGreen: #41d98d;
$colorDangerPurple: #544cd9;
$colorAccentGrape: #5e56f0;
$colorInfoPurple: #5e56f0;
$colorSteel: #7b7b97;
$colorNeutralLightGrey: #a0a1ba;
$colorGray: #b1b1b1;
$colorDuckEggBlue: #c7f9fa;
$colorLightPurple: #c9c6ff;
$colorAccentFuschia: #d20061;
$colorLightGray: #dadada;
$colorNeutralDawn: #e6e6f1;
$colorTomato: #ea3939;
$colorLightCyan: #eafeff;
$colorRed: #eb2a2a;
$colorOrange: #ef670d;
$colorVeryLightGray: #f0f0f0;
$colorLightTeal: #f0feff;
$colorNeutralDay: #f6f6fa;
$colorLightYellow: #fffde5;
$colorWhite: #ffffff;

// Mapping of random colors to closest Neon color -or- FC-UI Neon spec

$color-randome-white1: $color-gray-100; // #fbfbfe;
$color-random-cyan1: $color-navy-600; // #005a60;
$color-random-cyan2: $color-student-primary-teal; // #007279;
$color-random-cyan3: $color-cyan-500; // #00838c;
$color-random-cyan4: $color-navy-600; // #00595f;
$color-random-cyan5: $color-gray-600; // #677274;
$color-random-cyan6: $color-gray-200; // #dfe2e2;
$color-random-cyan7: $color-teal-500; // #03747c;
$color-random-gray1: $color-gray-100; // #f3f3f3;
$color-random-gray2: $color-gray-100; // #e4e4eb;
$color-random-gray3: $color-gray-400; // #979797;
$color-random-gray4: $color-gray-300; // #cccccc;
$color-random-gray5: $color-gray-200; // #d0cccc;
$color-random-gray6: $color-gray-600; // #717171;
$color-random-gray7: $color-gray-100; // #f6f7f8;
$color-random-gray8: $color-white; // #eeeeee;
$color-random-gray9: $color-gray-800; // #3c3d3f;
$color-random-gray10: $color-gray-100; // #f0f0f6;
$color-random-gray11: $color-gray-300; // #c2c2c2;
$color-random-gray12: #dddddd;
$color-random-blue1: $color-navy-100; // #ebebf8;
$color-random-blue2: $color-navy-100; // #d9d9f2;
$color-random-blue3: $color-white; // #e2e0ff;
$color-random-blue4: $color-white; // #e5f3ff;
$color-random-blue5: $color-white; // #efeffb;
$color-random-blue6: $color-cyan-400; // #0075db;
$color-random-blue7: $color-navy-400; // #665bdb;
$color-random-blue8: $color-purple-800; // #4b44c5;
$color-random-blue9: $color-navy-600; // #19182d;
$color-random-blue10: $color-purple-700; // #4e47c5;
$color-random-blue11: $color-navy-800; // #0d0c17;
$color-random-blue12: $color-purple-800; // #312d77;
$color-random-blue13: $color-gray-200; // #dbdbeb;
$color-random-blue14: $color-navy-500; // #1f1f9b;
$color-random-blue15: $color-gray-200; // #e0dbfc;
$color-random-blue16: #5e56f1;
$color-random-red1: $color-orange-100; // #ff9888;
$color-random-red2: $color-orange-100; //  #ff6047;
$color-random-red3: $color-orange-100; //  #ffd1ca;
$color-random-red4: $color-orange-100; //  #feedef;
$color-random-red5: $color-orange-500; // #c70029;
$color-random-red6: $color-red-500; // #e31b30;
$color-random-red7: $color-red-100; //  #fbddda;
$color-random-red8: $color-red-700; // #930c0c;
$color-random-red9: $color-red-600; // #ba100c;
$color-random-red10: $color-red-600; // #bf0023;
$color-random-red11: $color-orange-100; // #fdedef;
$color-random-red12: #ff0000;
$color-random-red13: #ff3232;
$color-random-black1: $color-gray-800; // #2d2d2d;
$color-random-black2: $color-gray-800; // #272728;
$color-random-black3: $color-black; // #171717;
$color-random-green1: $color-cyan-100; // #d9fcf2;
$color-random-green2: $color-green-600; // ; // #0fab7b;
$color-random-green3: #8bc200;

// Mapping to either closest Neon Color -or- FC-UI Neon spec
// these override existing defs imported from
// node_modules/@naviance/fc.css/src/colors/colors.scss

$color-ui-text-darkest: $color-gray-800; // #000000;
$color-student-midnight-teal: $color-teal-800; // #003738;
$color-student-primary-teal: $color-teal-600; // #00727a;
$color-ui-primary: $color-student-primary-teal;

$color-student-primary-teal-link: $button-primary; // components/ClickHOC/styles.scss
$color-student-primary-teal-button: $button-primary; // components/ClickHOC/styles.scss
$color-login-button-teal-gradient-dark: $button-primary; // components/NewLogin/style.scss
$color-login-button-teal-gradient-light: $button-primary; // components/NewLogin/style.scss
// for when hubs/supermatch/events micros are updated - $color-college-footer-clickable: $button-primary; // components/CollegeFooter/style.scss

$color-student-accent-sky: $color-cyan-500; // #009cd5;
$color-student-dark-teal: $color-teal-700; // #014b4d;
$color-student-medium-teal: $color-teal-700; // #016469;
$color-student-neutral-midnight: $color-navy-800; // #07081c;
$color-ui-text-dark: $color-student-neutral-midnight;
$color-ui-background-dark-hover: $color-black; // #161616;
$color-student-neutral-twilight: $color-black; // #1c1d39;
$color-ui-background-dark: $color-gray-800; // #2b2b2b;
$color-ui-text-default: $color-gray-600; // #323a45;
$color-ui-background-success: $color-green-500; // #41d98d;
$color-student-neutral-dark-grey: $color-gray-600; // #4e4f65;
$color-ui-text-darkish: $color-student-neutral-dark-grey;
$color-student-accent-grape: $color-purple-500; // #5e56f0;
$color-ui-background-info: $color-student-accent-grape;
$color-hobsons-primary-gray: $color-gray-600; // #616365;
$color-student-neutral-grey: $color-gray-500; // #737391;
$color-ui-text-muted: $color-gray-600; // #747587;
$color-student-accent-lime: $color-green-400; // #8bc100;
$color-student-alert-green: $color-green-400; // #9acc41;
$color-student-neutral-light-grey: $color-gray-400; // #a0a1ba;
$color-student-accent-plum: $color-purple-500; // #a800b7;
$color-ui-background-home-highlight: $color-orange-400; // #a86628;
$color-student-light-teal: $color-teal-100; // #c7f9fa;
$color-student-accent-fuschia: $color-magenta-500; // #d20061;
$color-ui-border-active: $color-student-accent-fuschia; // #d20061;
$color-ui-background-danger: $color-red-500; // #de3535;
$color-ui-border: $color-gray-200; // #dfdfdf;
$color-hobsons-primary-magenta: $color-magenta-500; // #e40060;
$color-ui-background-call-to-action: $color-hobsons-primary-magenta;
$color-student-neutral-dawn: $color-gray-100; // #e6e6f1;
$color-ui-background-lightish: $color-student-neutral-dawn; // #e6e6f1;
$color-student-alert-red: $color-red-500; // #e71d1d;
$color-ui-highlight-lightish: $color-navy-100; // #eaf2f8;
$color-student-alert-orange: $color-orange-400; // #ef670d;
$color-student-neutral-day: $color-gray-100; // #f6f6fa;
$color-student-alert-yellow: $color-yellow-100; // #ffe59a;
$color-ui-background-warning: $color-student-alert-yellow; // #ffe59a;
$color-ui-background-default: $global-background-white; // #ffffff;
$color-ui-text-light: $text-color-white; // #ffffff;

// */ // END - UNCOMMENT THIS SECTION TO ENABLE THE NEON OVERRIDES

// Colors with Opacity - using color defs to help with move to Neon
$color-ui-shadow: rgba($color-random-gray11, 0.3);
$color-ui-background-lighten: rgba($color-white, 0.2);
$color-ui-background-translucent-dark: rgba($color-black, 0.8);
$color-ui-shadow-random-black1: rgba($color-black, 0.03); // rgba(0, 0, 0, 0.03)
$color-ui-shadow-random-black2: rgba($color-black, 0.25); // rgba(0, 0, 0, 0.25)
$color-ui-shadow-random-black3: rgba($color-black, 0.5); // rgba(0, 0, 0, 0.5)
$color-ui-shadow-random-black4: rgba($color-black, 0.15); // rgba(0, 0, 0, 0.15)
$color-ui-shadow-random-black5: rgba($color-black, 0); // rgba(0, 0, 0, 0)
$color-ui-shadow-random-black6: rgba($color-black, 0.87); // rgba(0, 0, 0, 0.87)
$color-ui-shadow-random-black7: rgba($color-black, 0.2); // rgba(0, 0, 0, 0.2)
$color-ui-shadow-random-black8: rgba($color-black, 0.17); // rgba(0, 0, 0, 0.17)
$color-ui-shadow-random-black9: rgba($color-black, 0.4); // rgba(0, 0, 0, 0.4)
$color-ui-shadow-random-black10: rgba($color-black, 0.75); // rgba(0, 0, 0, 0.75)
$color-ui-shadow-random-black11: rgba($color-black, 0.36); // rgba(0, 0, 0, 0.36)
$color-ui-shadow-random-black12: rgba($color-black, 0.1); // rgba(0, 0, 0, 0.1)
$color-ui-shadow-random-cyan1: rgba($color-random-cyan3, 0.5); // rgba(0, 131, 140, 0.5)
$color-ui-shadow-random-cyan2: rgba($color-student-primary-teal, 0.5); // rgba(1, 119, 129, 0.5);
$color-ui-shadow-random-white1: rgba($color-white, 0); // rgba(255, 255, 255, 0)
$color-ui-shadow-random-white2: rgba($color-white, 0.75); // rgba(255, 255, 255, 0.75)
$color-ui-shadow-random-red1: rgba($color-ui-background-danger, 0.5); // rgba(222, 53, 53, 0.502)
$color-ui-shadow-random-red2: rgba($color-random-red13, 0.7); // rgba(255, 50, 50, .7)
$color-ui-shadow-random-blue1: rgba($color-student-accent-grape, 0.5); // rgba(94, 86, 240, 0.502)
$color-ui-shadow-random-gray1: rgba($color-random-gray8, 0.01); // rgba(238, 238, 238, 0.01)
$color-ui-shadow-random-gray2: rgba($color-random-gray12, 0.02); // rgba(221, 221, 221, 0.2)
$color-ui-shadow-random-gray3: $color-ui-shadow; // rgba(194, 194, 194, 0.3)
