@import 'theme/breakpoints';

@media (min-width: $largePhone) {
  .showOnTablet.showOnTablet {
    position: static;
    width: auto;
    height: auto;
    clip: auto;
    margin: 0;
  }
}
