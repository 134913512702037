@import 'theme/variables';
@import 'grid/grid';
@import 'layout/layout';
@import 'theme/breakpoints';
@import 'theme/spacing';

.navMobileMenu {
  @extend .fc-main-nav__mobile-menu;
  font-size: 18px;
  height: auto;
  line-height: normal;

  @media (max-width: $smallDesktop) {
    display: flex;
    align-items: center;
    min-width: 0;
  }
}

.navMobileMenuButton {
  @extend .fc-main-nav__mobile-button;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 21px;
}

.navMobileMenuIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;

  svg {
    margin: 0;
  }
}
