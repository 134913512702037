@import 'theme/colors';
@import 'theme/variables';
@import 'grid/grid';
@import 'layout/layout';
@import 'theme/breakpoints';
@import 'theme/spacing';
@import 'theme/variables';

.header {
  padding: $spc-l $spc-l ($spc-xxl + $spc-l) $spc-l;
  background-color: $color-student-primary-teal;
  color: $colorWhite;

  a {
    color: inherit;
  }
}

.title {
  font-family: $font-alternate;
  font-weight: 100;
  margin-bottom: 0;
}

.searchForm {
  display: flex;
  align-items: center;

  &:not(.fixedScope) {
    @media (min-width: $lg) and (max-width: $xl) {
      margin-left: $spc-m;
    }
  }
}

.combo {
  width: 100%;
  margin-right: $spc-m;
}

.inputWrap {
  display: flex;
  flex: 1;
  margin-right: $spc-m;
  border-radius: 4px;
  padding: $spc-xs;
  background-color: $colorWhite;
  align-items: center;
  overflow: hidden;

  input {
    // exact height for cross-browser compat
    height: 2.25rem;
  }

  svg {
    width: 20px;
    height: 20px;
    color: $color-student-primary-teal;
  }
}

.fixedScope {
  margin-bottom: $spc-m;
}

@media (max-width: $smallDesktop) {
  .comboWrap {
    padding-bottom: $spc-m;
  }
}

.careers2Bg {
  background-color: $color-random-blue9;
}
