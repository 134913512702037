@import '__NEON_CDN_UN_VERSIONED__/fonts.css';
@import 'styles/shared/_design-tokens';

$roboto: $font-roboto-fallback-stack;
$karla: $font-karla-fallback-stack;

// /* BEGIN - Uncomment to apply Roboto font globally

// Set the font-family to whatever Neon is declaring as $font-roboto-fallback-stack
html,
body {
  font-family: $roboto !important;
}

// Set all children of body tag that do not have a class ending in _neonFontOverride to inherit
// Note: see examples in comments at bottom about how to override
html * :not([class$='_neonFontOverride']) {
  font-family: inherit;
}
body * :not([class$='_neonFontOverride']) {
  font-family: inherit;
}

// */ //END - Uncomment to apply Roboto font globally

/* How to override Neon Font for very specific use cases

  create and apply from a new class in this file

  Note: this will override the elements nested within it, so be specific
    and/or re-specifiy roboto for children

  Example: everything within the class
  .anyNameHere_neonFontOverride {
    font-family: 'Courier New', Courier, monospace;
  }

  Example: everything within the class and reset <p> tags to $roboto
  Note: to use $roboto, you need to import themeNeon/neon.scss
  .welcome_neonFontOverride {
    font-family: 'Courier New', Courier, monospace;
    & p {
      font-family: $roboto;
    }
  }

  Example: everything <p> tag within the class
  .welcome_neonFontOverride {
    & p {
      font-family: 'Courier New', Courier, monospace;
    }
  }

*/
