@import 'theme/colors';
@import 'theme/variables';
@import 'theme/spacing';

.menuItem {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: $spc-xs $spc-m;
  white-space: break-spaces;
  font-family: Ubuntu;
  cursor: pointer;

  &:hover {
    background: $button-secondary-hover;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $color-gray-800;
  }

  &:focus {
    background: $button-secondary-hover;
    box-shadow: 0px 0px 0px 1px $color-white, 0px 0px 0px 2px $button-primary,
      0px 0px 0px 4px $button-secondary-active;
  }

  @media (max-width: 48.1rem) {
    padding-left:0px;
  }
}

// @media (max-width: 48.1rem) {
//   .dottedWrapper {
//     padding: $spc-m;
//     border-bottom: 1px dashed $color-ui-border;
//   }
// }

.icon {
  margin: 0;
  margin-right: 10px !important;
  svg {
    margin-bottom: -6px;
  }
}

.icon[data-icon='heart-solid'] {
  fill: $button-tertiary;
}

.link {
  color: $color-gray-800;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 48.1rem) {
    padding-left:0px;
  }
}
