@import 'theme/colors';
@import 'theme/spacing';
@import 'theme/variables';

.inputWrap {
  display: flex;
  flex: 1;
  margin-right: $spc-m;
  border-radius: 4px;
  padding: $spc-xs;
  background-color: $colorWhite;
  align-items: center;

  input {
    // exact height for cross-browser compat
    height: 2.25rem;
  }

  svg {
    width: 20px;
    height: 20px;
    color: $color-student-primary-teal;
  }
}

.icon {
  margin: 0.25rem 0.25rem 0.25rem 0.5rem;
}

.label {
  display: flex;
  flex: 1;
  padding: 0;
}
