@import 'theme/colors';
@import 'theme/variables';
@import 'grid/grid';
@import 'layout/layout';
@import 'theme/breakpoints';
@import 'theme/spacing';

$linkHighlightLineWeight: 0.25rem;

.header {
  font-family: $font-alternate;
}

.headerTopSection {
  background-color: $color-student-dark-teal;
  color: $color-ui-text-light;
  text-align: right;
  padding: 0 $spc-l;
  height: 2rem;
  font-size: 0.8625rem;
  line-height: 2rem;
}

.headerTopSectionLink {
  color: inherit;
  margin-left: $spc-s;

  &:hover {
    text-decoration: underline;
  }
}

.nav {
  display: flex;
  color: $colorWhite;
  height: 5rem;
  font-size: 1.728rem;
  background-color: $color-student-primary-teal;
  justify-content: space-around;
}

.navContainer {
  @extend .fc-main-nav__container;
  align-items: center;
  width: 100%;
  padding: 0 $spc-l;
  margin: 0;
}

.logoWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 100;
  color: $colorWhite;
}

.logo {
  max-width: 10.75rem;
  margin-right: $spc-xs;
}

.emblem {
  max-width: 18.75rem;
  margin-right: $spc-s;
}

.logoDivider {
  margin: 0 $spc-s;
  font-style: normal;
  color: $colorWhite;
  line-height: 1.667rem;
}

.navList {
  @extend .fc-main-nav__list;
  width: auto;
  margin-bottom: 0;
  text-align: center;
  align-items: stretch;
}

.navItem {
  list-style-type: none;
  white-space: nowrap;
  margin: 0;
}

.navLink {
  text-decoration: none;
  font-size: 1rem;
  display: block;
  color: $colorWhite;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  &:hover {
    text-decoration: none;
    color: $colorWhite;
    .navLinkBorder {
      border-bottom: 4px solid white;
    }
  }
}

.navLinkBorder {
  padding: 0.2rem $spc-s;
  margin: 0 $spc-xs/2;
  font-size: 1rem;

  border-bottom: $linkHighlightLineWeight solid transparent;
  border-top: $linkHighlightLineWeight solid transparent;
}

.activeLink {
  .navLinkBorder {
    padding: 0.2rem 1rem;
    border-bottom: $linkHighlightLineWeight solid white;
  }
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: $spc-xs;
}

.schoolName {
  text-transform: uppercase;
}

.messages {
  padding-right: $spc-m;
  color: inherit;

  a {
    color: $colorWhite;
  }
}

.mailIcon {
  svg {
    width: 18px;
    height: 18px;
  }
}

.selectUserCombo {
  color: inherit;
  display: flex;
  min-width: 150px;
  display: inline-block;
}
.labelCls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  // this is a very specific override to the ComboBox label
  span {
    font-weight: normal;
    padding: 0;
    margin: 0;
  }
}
.selectCls {
  display: flex;
  align-items: center;
  padding: 0 0 0 $spc-s;
  border-bottom: none;
  min-width: 0;
  flex: none !important;

  select {
    padding: 0 2rem 0 0;
    min-width: 0;
  }
  svg {
    width: 14px;
    height: 14px;
  }
}
.languagesComboBox {
  display: inline-block;
  margin: 0 1em 0 0.5em;
}
.languagesDropdown {
  min-width: 0;
  line-height: $spc-l;
  select {
    padding: 0 2rem 0 0.5rem;
    min-width: 0;
  }
  figure {
    svg {
      height: 15px;
      width: 15px;
    }
  }
}
.languagesDropdownContainer {
  display: inline-block;
  width: 300px;
  text-align: right;
}
.bigMenu {
  position: absolute;
  font-size: 1rem;
  z-index: 1000;
  min-width: 18rem;
}

.navMenu {
  font-size: 1rem;
  z-index: 100;
  min-width: 25 rem;
}

@media (max-width: $smallDesktop) {
  .headerTopSection {
    display: none;
  }

  .mailIcon {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  .badge {
    position: absolute;
    top: -3px;
    left: -3px;
  }

  .messages {
    padding-right: $spc-xs;
    position: relative;
  }

  .selectUserCombo {
    min-width: 0;
  }

  .labelCls {
    flex-direction: column;
    span {
      display: none;
    }
    select {
      padding-right: 2rem;
      min-width: 0;
    }
  }

  .selectCls {
    flex: 1 !important;
  }
}

.careers2Menu {
  position: absolute;
  font-size: 1rem;
  z-index: 1000;
  min-width: 18rem;
  svg {
    color: $colorBlack;
  }
}

.careers2Bg {
  background-color: $color-random-blue9;
}

.careers2TopBg {
  background-color: $color-random-blue11;
}

.c2Nav {
  display: flex;
  color: $colorWhite;
  height: 48px;
  padding-left: 14px;
  background-color: $color-random-blue9;
}

.c2NavContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .mailIcon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .badge {
    position: absolute;
    top: -3px;
    left: -3px;
  }

  .messages {
    padding-right: $spc-xs;
    position: relative;
  }

  .selectUserCombo {
    min-width: 0;
  }

  .labelCls {
    flex-direction: column;
    span {
      display: none;
    }
    select {
      padding-right: 2rem;
      min-width: 0;
    }
  }

  .selectCls {
    flex: 1 !important;
  }

  .c2FlexContainer {
    display: flex;
    align-items: center;
  }

  .withSeparator {
    & > {
      ::before {
        content: ' ';
        position: relative;
        height: 37px;
        width: 0px;
        border-left: 2px solid $color-random-blue10;
        opacity: 0.6;
      }
    }
  }

  .c2LogoutBtn {
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 600;
    height: fit-content;
    padding: $spc-xs $spc-m;
    margin: 0 $spc-s;
    line-height: 24px;
    letter-spacing: 0.26px;
    border-radius: 5px;
    &::before {
      display: none;
    }
    &:hover {
      border-width: 1px;
      padding: $spc-xs $spc-m;
    }
  }

  .c2LogoWrapper {
    display: inline-flex;
    padding: $spc-xs;
  }

  .c2Logo {
    margin: 0;
    svg {
      width: 127px;
      height: 30px;
      margin: 0;
      path {
        fill: $colorWhite;
      }
    }
    @media (max-width: $tablet - 0.1rem) {
      display: none;
    }
  }

  .c2Emblem {
    @media (min-width: $tablet) {
      display: none;
    }
  }

  .hamburger {
    margin-right: $spc-m;
  }

  .navLinkBorder {
    font-family: Ubuntu;
  }

  .activeLink {
    .navLinkBorder {
      border-bottom: $linkHighlightLineWeight solid transparent;
    }
  }

  .navLink {
    cursor: pointer;
    &:hover {
      background-color: $color-random-blue12;
      text-decoration: none;
      .navLinkBorder {
        border-bottom: $linkHighlightLineWeight solid transparent;
      }
    }

    &[aria-expanded='true'] {
      background-color: $color-random-blue12;
    }
    &:focus {
      outline: none;
    }
  }

  .noMobile {
    @media (max-width: $smallDesktop - 0.1rem) {
      display: none;
    }
  }

  .noDesktop {
    @media (min-width: $smallDesktop) {
      display: none;
    }
  }

  .selectUserCombo {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
  }
}

a.skipMain {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skipMain:focus,
a.skipMain:active {
  color: $colorWhite;
  background-color: $colorBlack;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}
