// 1px
$spc-xxs: 0.0625rem;

// 5px
$spc-xs: 0.31rem;

// 10px
$spc-s: 0.62rem;

// 15px
$spc-m: 0.93rem;

// 20px
$spc-l: 1.25rem;

// 40px
$spc-xl: 2.5rem;

// 60px
$spc-xxl: 3.75rem;
