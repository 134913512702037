.pointer {
  cursor: pointer;
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.uppercase {
  text-transform: uppercase;
}
