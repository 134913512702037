@import 'theme/colors';

// fonts
$font-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
$font-alternate: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;

$border: 1px solid $colorLightGray;

// FC.CSS typography-baseline - variables only
$ten-pixel-root: 62.5%;
$eleven-pixel-root: 68.75%;
$twelve-pixel-root: 75%;

$ms-base: 1.2rem;
$ms-ratio: 1.2; // minor third

$baseline-ratio: 1.66667; // major sixth
$baseline-unit: 2rem;
$baseline-increment: $baseline-unit / 4;

$body-font: 'Ubuntu', Arial, Helvetica, sans-serif;
$heading-font: 'Source Sans Pro', Arial, Helvetica, sans-serif;
$boldish: 600;

$body-font-family: $body-font;
$heading-letter-spacing: 0.025em;
$body-font-size: ms(0);
$h1-font-size: ms(4);
$h2-font-size: ms(3) - 0.1rem; // Tweak to fit into baseline without ascenders and descenders touching
$h3-font-size: ms(2);
$h4-font-size: ms(1);
$h5-font-size: ms(0);
$h6-font-size: ms(0);

$border-radius: $baseline-increment / 2;
$box-shadow: 0 0 2px 2px $color-ui-shadow;

$spacing-sm: $baseline-increment;
$spacing-med: $baseline-increment * 3;
$spacing-lg: $baseline-unit * 2;
$spacing-xl: $baseline-unit * 3.5;
