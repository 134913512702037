@import 'theme/colors';
@import 'theme/spacing';
@import 'theme/breakpoints';

%common {
  margin: 0;
  padding: $spc-s;
  border: 1px solid $color-ui-border;
  background-color: $colorWhite;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }

  &:invalid {
    border-color: $color-ui-background-danger;
  }
}

.input {
  @extend %common;
  display: block;
  font-family: inherit;
  font-size: inherit;

  &.inline {
    width: auto;
    display: inline-block;
    margin-right: $spc-l;
  }
}

.textarea {
  @extend %common;
  font-family: inherit;
  font-size: inherit;
}

.bare {
  margin: 0;
  border: none;
  width: 100%;
  flex: 1;
  -webkit-appearance: none;
}

.validationWarning {
  font-weight: normal;
}

.icon {
  color: $color-student-alert-red;
}

@media (max-width: $smallPhone) {
  .bare {
    &::placeholder {
      font-size: 0.85em;
    }
  }
}
